

const AUDIO_CONFIG = {
  sources: [
    "assets/musiques/nobonoko_Strawberry_Cake.wav",
    "assets/musiques/nobonoko_Picobossa.wav",
    "assets/musiques/Tohomoko_Aerodynamics.wav",
  ],
  playlist: [
    {
      title1: "ᰔ ⊹˚₊ Picobossa ₊˚⊹ ᰔ",
      title2: "Nobonoko",
      source: "assets/musiques/nobonoko_Picobossa.wav",
      image: "./assets/musiques/picobossa.png",
    },
    {
      title1: "ᰔ ⊹˚₊ Aerodynamics ₊˚⊹ ᰔ",
      title2: "Tohomoko",
      source: "assets/musiques/Tohomoko_Aerodynamics.wav",
      image: "./assets/musiques/aerodynamics.jpg",
    },
    {
      title1: "ᰔ ⊹˚₊ Strawberry Cake ₊˚⊹ ᰔ",
      title2: "Nobonoko",
      source: "assets/musiques/nobonoko_Strawberry_Cake.wav",
      image: "./assets/musiques/strawberry_cake.jpg",
    },
  ],
}

const IMAGE_SOURCES = [
  "assets/musiques/strawberry_cake.jpg",
  "assets/musiques/picobossa.png",
  "assets/musiques/aerodynamics.jpg",
]

// Preload assets
const preloadAssets = () => {
  AUDIO_CONFIG.sources.forEach((source) => {
    const audio = new Audio()
    audio.src = source
    audio.preload = "auto"
  })

  IMAGE_SOURCES.forEach((source) => {
    const img = new Image()
    img.src = source
  })
}

// Window Manager Class
class WindowManager {
  constructor() {
    this.windows = document.querySelectorAll(".window");
    this.activeProjectWindows = new Map();
    this.zIndex = 2;
    this.init();
  }

  init() {
    this.setupIcons();
    this.setupWindows();
    this.setupProjects();
  }

  bringToFront(window) {
    this.zIndex += 1;
    window.style.zIndex = this.zIndex;
  }

  centerWindow(window) {
    const windowWidth = window.offsetWidth;
    const windowHeight = window.offsetHeight;
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    const left = (screenWidth - windowWidth) / 2;
    const top = (screenHeight - windowHeight) / 2;

    window.style.left = `${left}px`;
    window.style.top = `${top}px`;
  }

  showWindow(window) {
    window.style.display = "block";
    this.centerWindow(window);
    gsap.fromTo(
      window,
      { opacity: 0, y: "-100px", scale: 0 },
      { opacity: 1, y: 0, scale: 1, duration: 0.5, ease: "power2.out" }
    );
    this.bringToFront(window);
  }

  setupIcons() {
    document.querySelectorAll(".icon").forEach((icon) => {
      icon.addEventListener("click", () => {
        const target = icon.getAttribute("data-target");
        const window = document.querySelector(`.window--${target}`);
        if (window) this.showWindow(window);
      });
    });
  }

  setupWindows() {
    this.windows.forEach((window) => {
      if (window.classList.contains("window--paint")) {
        this.makeDraggable(window, true);
      } else {
        this.makeDraggable(window); 
      }
  
      window.addEventListener("click", () => this.bringToFront(window));
  
      const controls = window.querySelector(".title-bar__controls");
      if (controls) {
        controls.querySelectorAll("div").forEach((control) => {
          control.addEventListener("click", () => (window.style.display = "none"));
        });
      }
    });
  }


  setupProjects() {
    document.querySelectorAll(".project").forEach((project) => {
      project.addEventListener("click", (e) => {
        e.stopPropagation();
        const projectId = project.getAttribute("data-id");
        const projectWindow = document.querySelector(
          `.window--project-details[data-project-id="${projectId}"]`
        );

        if (projectWindow) {
          this.showWindow(projectWindow);
          this.activeProjectWindows.set(projectId, projectWindow);
        }
      });
    });
  }
  
  makeDraggable(element, dragTitleBarOnly = false) {
    const pos = { x: 0, y: 0, startX: 0, startY: 0 };
  
    const dragElement = dragTitleBarOnly ? element.querySelector(".title-bar") : element;
  
    if (!dragElement) {
      return;
    }
  
    const dragStart = (e) => {
      if (e.type === "mousedown") {
        pos.startX = e.clientX;
        pos.startY = e.clientY;
      } else {
        pos.startX = e.touches[0].clientX;
        pos.startY = e.touches[0].clientY;
      }
      pos.x = element.offsetLeft;
      pos.y = element.offsetTop;
  
      if (e.type === "mousedown") {
        document.addEventListener("mousemove", drag);
        document.addEventListener("mouseup", dragEnd);
      }
    };
  
    const drag = (e) => {
      e.preventDefault();
      const clientX = e.type === "mousemove" ? e.clientX : e.touches[0].clientX;
      const clientY = e.type === "mousemove" ? e.clientY : e.touches[0].clientY;
  
      const dx = clientX - pos.startX;
      const dy = clientY - pos.startY;
  
      element.style.left = `${pos.x + dx}px`;
      element.style.top = `${pos.y + dy}px`;
    };
  
    const dragEnd = () => {
      document.removeEventListener("mousemove", drag);
      document.removeEventListener("mouseup", dragEnd);
    };
  
    dragElement.addEventListener("mousedown", dragStart);
    dragElement.addEventListener("touchstart", dragStart, { passive: false });
    dragElement.addEventListener("touchmove", drag, { passive: false });
    dragElement.addEventListener("touchend", dragEnd);
  }
}
// Audio Player Class
class AudioPlayer {
  constructor() {
    this.player = document.querySelector(".audio-player audio")
    this.controls = {
      volume: document.querySelector(".music-section__slider"),
      progress: document.querySelector(".audio-controls__progress-bar"),
      playIcon: document.querySelector(".audio-controls__play-icon"),
      pauseIcon: document.querySelector(".audio-controls__pause-icon"),
      title: document.querySelector(".audio-title"),
      subTitle: document.querySelector(".audio-sub-title"),
      image: document.querySelector(".audio-image"),
    }
    this.currentTrack = 0
    this.init()
  }

  init() {
    this.loadTrack(this.currentTrack)
    this.setupEventListeners()
    this.updateVolume()
  }

  loadTrack(index) {
    const track = AUDIO_CONFIG.playlist[index]
    this.player.src = track.source
    this.controls.title.textContent = track.title1
    this.controls.subTitle.textContent = track.title2
    this.controls.image.src = track.image
  }

  setupEventListeners() {
    this.controls.volume.addEventListener("input", () => this.updateVolume())
    this.player.addEventListener("timeupdate", () => this.updateProgress())
    this.controls.progress.addEventListener("click", (e) => this.seek(e))

    document.querySelector(".audio-controls__toggle").addEventListener("click", () => {
      this.player.paused ? this.player.play() : this.player.pause()
    })

    document.querySelector(".audio-controls__prev-button").addEventListener("click", () => {
      this.currentTrack = (this.currentTrack - 1 + AUDIO_CONFIG.playlist.length) % AUDIO_CONFIG.playlist.length
      this.loadTrack(this.currentTrack)
    })

    document.querySelector(".audio-controls__next-button").addEventListener("click", () => {
      this.currentTrack = (this.currentTrack + 1) % AUDIO_CONFIG.playlist.length
      this.loadTrack(this.currentTrack)
    })

    this.player.addEventListener("play", () => {
      this.controls.playIcon.style.display = "none"
      this.controls.pauseIcon.style.display = "block"
    })

    this.player.addEventListener("pause", () => {
      this.controls.playIcon.style.display = "block"
      this.controls.pauseIcon.style.display = "none"
    })
  }

  updateVolume() {
    this.player.volume = this.controls.volume.value / 400
  }

  updateProgress() {
    if (this.player.duration) {
      this.controls.progress.value = (this.player.currentTime / this.player.duration) * 100
    }
  }

  seek(event) {
    const percent = event.offsetX / this.controls.progress.offsetWidth
    this.player.currentTime = percent * this.player.duration
  }
  
}



// Paint Class
class Paint {
  constructor() {
    this.window = document.querySelector(".window--paint")
    if (!this.window) return

    this.canvas = this.window.querySelector(".content canvas")
    this.ctx = this.canvas.getContext("2d")
    this.isPainting = false
    this.currentColor = "#000000"

    this.init()
  }

  init() {
    this.initializeCanvas()
    this.setupEventListeners()
  }

  initializeCanvas() {
    this.ctx.fillStyle = "#FFFFFF"
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height)
  }

  setupEventListeners() {
    this.setupDrawingEvents()
    this.setupColorButtons()
    this.setupSaveButton()
  }

  setupDrawingEvents() {
    const getCoordinates = (e, canvas) => {
      const rect = canvas.getBoundingClientRect()
      return {
        x: (e.clientX || e.touches[0].clientX) - rect.left,
        y: (e.clientY || e.touches[0].clientY) - rect.top,
      }
    }

    const startDrawing = (e) => {
      this.isPainting = true
      const coords = getCoordinates(e, this.canvas)
      this.lastX = coords.x
      this.lastY = coords.y
    }

    const draw = (e) => {
      if (!this.isPainting) return

      const coords = getCoordinates(e, this.canvas)
      this.ctx.strokeStyle = this.currentColor
      this.ctx.lineJoin = "round"
      this.ctx.lineWidth = 5

      this.ctx.beginPath()
      this.ctx.moveTo(this.lastX, this.lastY)
      this.ctx.lineTo(coords.x, coords.y)
      this.ctx.stroke()
      ;[this.lastX, this.lastY] = [coords.x, coords.y]
    }

    const stopDrawing = () => (this.isPainting = false)

    this.canvas.addEventListener("mousedown", startDrawing)
    this.canvas.addEventListener("mousemove", draw)
    this.canvas.addEventListener("mouseup", stopDrawing)
    this.canvas.addEventListener("mouseout", stopDrawing)

    this.canvas.addEventListener("touchstart", (e) => {
      e.preventDefault()
      startDrawing(e)
    })
    this.canvas.addEventListener("touchmove", (e) => {
      e.preventDefault()
      draw(e)
    })
    this.canvas.addEventListener("touchend", stopDrawing)
  }

  setupColorButtons() {
    const colors = {
      ".blackButton": "#000000",
      ".greyButton": "#808080",
      ".pinkButton": "#FBD2F6",
    }

    Object.entries(colors).forEach(([selector, color]) => {
      this.window.querySelector(selector).addEventListener("click", () => {
        this.currentColor = color
      })
    })
  }

  setupSaveButton() {
    this.window.querySelector(".save-button").addEventListener("click", () => {
      const link = document.createElement("a")
      link.download = "cute_drawing.png"
      link.href = this.canvas.toDataURL("image/png")
      link.click()
    })
  }
}

// Clock Class
class Clock {
  constructor() {
    this.element = document.querySelector(".footer__time")
    this.start()
  }

  start() {
    this.update()
    setInterval(() => this.update(), 1000)
  }

  update() {
    const now = new Date()
    const hours = String(now.getHours()).padStart(2, "0")
    const minutes = String(now.getMinutes()).padStart(2, "0")
    this.element.textContent = `${hours}:${minutes}`
  }
}
// Love Tester Class
class LoveTester {
  constructor() {
    this.heartFill = document.querySelector(".heart-fill")
    this.resultElement = document.querySelector(".love-test__result")
    this.heartPath = document.querySelector(".heart-fill path") 
    this.setupEventListeners()
  }

  setupEventListeners() {
    document.querySelector(".love-test__button").addEventListener("click", () => this.calculate())
  }

  calculate() {
    const [name1, name2] = [...document.querySelectorAll(".love-test__input")].map((input) => input.value.toLowerCase())

    if (!name1 || !name2) {
      alert("Please enter both names ˆ𐃷ˆ")
      return
    }

    this.resetHeart()
    const percentage = this.calculatePercentage(name1 + name2)
    this.animate(percentage)
  }

  resetHeart() {
    this.heartFill.style.clipPath = "polygon(0 100%, 100% 100%, 100% 100%, 0 100%)"
    this.heartFill.style.transition = "none"
    void this.heartFill.offsetWidth
  }

  calculatePercentage(combinedNames) {
    const total = [...combinedNames].reduce((sum, char) => sum + char.charCodeAt(0), 0)
    return 60 + (total % 41)
  }

  animate(percentage) {
    this.heartFill.style.transition = "clip-path 2s ease-in-out"
    this.heartFill.style.clipPath = `polygon(0 ${100 - percentage}%, 100% ${100 - percentage}%, 100% 100%, 0 100%)`
  
    this.heartPath.setAttribute("fill", "#ffacf5")
  
    const resultsContainer = document.querySelector(".love-test__results")
    resultsContainer.classList.add("visible")
  
    let current = 0
    const interval = setInterval(() => {
      if (current <= percentage) {
        document.querySelector(".love-test__percentage").textContent = `${current}%`
        document.querySelector(".love-test__message").textContent = this.getMessage(current)
        current++
      } else {
        clearInterval(interval)
      }
    }, 20)
  }

  getMessage(percentage) {
    if (percentage < 70) return "( ˃▿˂ ) Love grows ‧₊♡˚"
    if (percentage < 90) return "(｡•́‿•̀｡) Sweet love ‧₊♡˚"
    return "(๑˃ᴗ˂)ﻭ Perfect love!‧₊♡˚"
  }
}














class Webcam {
  constructor() {
    this.video = document.getElementById('webcam');
    this.canvas = document.getElementById('ascii-canvas');
    this.startButton = document.getElementById('start-recording');
    this.stopButton = document.getElementById('stop-recording');
    this.mediaRecorder = null;
    this.recordedChunks = [];
    this.noCameraMessage = document.createElement('div');
    this.stream = null;
  }

  async init() {
    // Configuration du message d'erreur
    this.noCameraMessage.textContent = 'Caméra non activée';
    this.noCameraMessage.style.position = 'absolute';
    this.noCameraMessage.style.top = '50%';
    this.noCameraMessage.style.left = '50%';
    this.noCameraMessage.style.transform = 'translate(-50%, -50%)';
    this.noCameraMessage.style.color = '#ff69ee';
    this.noCameraMessage.style.fontSize = '24px';
    this.noCameraMessage.style.fontFamily = 'monospace';
    this.noCameraMessage.style.display = 'none';
    this.canvas.parentElement.appendChild(this.noCameraMessage);

    try {
      // Arrêter le flux existant s'il y en a un
      if (this.stream) {
        this.stream.getTracks().forEach(track => track.stop());
        this.stream = null;
      }

      const constraints = {
        video: {
          facingMode: 'environment', 
          width: { ideal: 1280 },
          height: { ideal: 720 },
        },
      };

      this.stream = await navigator.mediaDevices.getUserMedia(constraints);
      this.video.srcObject = this.stream;

      this.video.addEventListener('loadedmetadata', () => {
        this.adjustCanvasSize();
        this.video.play();
        this.drawAsciiArt();
      });

      const canvasStream = this.canvas.captureStream(30);
      this.mediaRecorder = new MediaRecorder(canvasStream, { mimeType: 'video/webm; codecs=vp9' });

      this.mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          this.recordedChunks.push(event.data);
        }
      };

      this.mediaRecorder.onstop = () => {
        const blob = new Blob(this.recordedChunks, { type: 'video/webm' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'cute_video.webm';
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(url);
      };

      this.noCameraMessage.style.display = 'none';
      this.canvas.style.display = 'block';
      this.startButton.disabled = false;
      this.stopButton.disabled = true;
    } catch (error) {
      console.error('Erreur d\'accès à la caméra :', error);
      this.displayNoCameraMessage();
    }

    this.startButton.addEventListener('click', () => this.startRecording());
    this.stopButton.addEventListener('click', () => this.stopRecording());
  }

  adjustCanvasSize() {

    const aspectRatio = this.video.videoWidth / this.video.videoHeight;
    const maxWidth = window.innerWidth * 0.9; 
    const maxHeight = window.innerHeight * 0.9; 

    let canvasWidth = maxWidth;
    let canvasHeight = canvasWidth / aspectRatio;

    if (canvasHeight > maxHeight) {
      canvasHeight = maxHeight;
      canvasWidth = canvasHeight * aspectRatio;
    }

    this.canvas.width = canvasWidth;
    this.canvas.height = canvasHeight;
  }

  startRecording() {
    this.recordedChunks = [];
    this.mediaRecorder.start();
    this.startButton.disabled = true;
    this.stopButton.disabled = false;
  }

  stopRecording() {
    this.mediaRecorder.stop();
    this.startButton.disabled = false;
    this.stopButton.disabled = true;
  }

  drawAsciiArt() {
    const context = this.canvas.getContext('2d');
    context.fillStyle = 'white';
    context.fillRect(0, 0, this.canvas.width, this.canvas.height);
    context.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);

    const imageData = context.getImageData(0, 0, this.canvas.width, this.canvas.height);
    const asciiArt = this.convertToAscii(imageData);

    context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    context.fillStyle = 'white';
    context.fillRect(0, 0, this.canvas.width, this.canvas.height);
    context.fillStyle = '#ff69ee';
    context.font = '8px monospace';

    const lines = asciiArt.split('\n');
    lines.forEach((line, index) => {
      context.fillText(line, 0, index * 8);
    });

    requestAnimationFrame(() => this.drawAsciiArt());
  }

  convertToAscii(imageData) {
    const chars = '@%#*+=-:. ';
    let asciiArt = '';
    for (let y = 0; y < imageData.height; y += 8) {
      for (let x = 0; x < imageData.width; x += 4) {
        const index = (y * imageData.width + x) * 4;
        const r = imageData.data[index];
        const g = imageData.data[index + 1];
        const b = imageData.data[index + 2];
        const brightness = (r + g + b) / 3;
        const charIndex = Math.floor((brightness / 255) * (chars.length - 1));
        asciiArt += chars[charIndex];
      }
      asciiArt += '\n';
    }
    return asciiArt;
  }

  displayNoCameraMessage() {
    this.noCameraMessage.style.display = 'block';
    this.canvas.style.display = 'none';
    this.startButton.disabled = true;
    this.stopButton.disabled = true;
  }

  close() {
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
      this.stream = null;
    }
    this.noCameraMessage.style.display = 'none';
    this.canvas.style.display = 'none';
    this.startButton.disabled = true;
    this.stopButton.disabled = true;
  }
}


document.addEventListener('DOMContentLoaded', () => {
  preloadAssets();
  new WindowManager();
  new AudioPlayer();
  new Paint();
  new Clock();
  new LoveTester();

  let webcamInstance = null;

  document.querySelector('.icon--webcam').addEventListener('click', () => {
    if (webcamInstance) {
      webcamInstance.close();
      webcamInstance = null;
    }

    webcamInstance = new Webcam();
    webcamInstance.init();
  });

  document.querySelector('.window--webcam .title-bar__close').addEventListener('click', () => {
    if (webcamInstance) {
      webcamInstance.close();
      webcamInstance = null;
    }
  });
});

// Global Mute Button

    const globalMuteButton = document.querySelector(".global-mute-button");
    let isMuted = false;

    const toggleGlobalMute = () => {
      isMuted = !isMuted; 

      const mediaElements = document.querySelectorAll("audio, video");

      mediaElements.forEach((media) => {
        media.muted = isMuted;
      });

      if (isMuted) {
        globalMuteButton.classList.remove("unmute");
        globalMuteButton.classList.add("mute");
      } else {
        globalMuteButton.classList.remove("mute");
        globalMuteButton.classList.add("unmute");
      }
    };

    globalMuteButton.addEventListener("click", toggleGlobalMute);